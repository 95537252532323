body {
  background-color: transparent;
}

[data-metabase-theme="light"][data-metabase-theme="light"] {
  --mb-color-text-primary: var(--mb-color-text-dark);
  --mb-color-text-secondary: var(--mb-color-text-medium);
  --mb-color-text-tertiary: var(--mb-color-text-light);
}

.EmbedFrame {
  color: var(--mb-color-text-primary);
  background-color: var(--mb-color-bg-dashboard);
}
/* A temporary solution until we migrated all colors in visualization to use semantic colors
   e.g. `text-primary`, `text-secondary`, `text-tertiary`. */
.EmbedFrame svg text {
  fill: var(--mb-color-text-primary);
}

.EmbedFrameHeader,
.EmbedFrameFooter {
  color: var(--mb-color-text-primary);
  background-color: var(--mb-color-bg-dashboard);
}

[data-metabase-theme="night"][data-metabase-theme="night"],
/**
  * role="tooltip" works with both legacy and Mantine popover,
  * the legacy Popover however, has the role "tooltip", but with data-theme="popover",
  * so we need to target exactly just tooltips.
  */
[role="tooltip"]:is([data-theme~="tooltip"], :global(.emotion-Tooltip-tooltip)) {
  --mb-color-text-primary: color-mix(
    in srgb,
    var(--mb-color-text-white) 90%,
    transparent
  );
  --mb-color-text-secondary: color-mix(
    in srgb,
    var(--mb-color-text-white) 65%,
    transparent
  );
  --mb-color-text-tertiary: color-mix(
    in srgb,
    var(--mb-color-text-white) 45%,
    transparent
  );
  --mb-color-bg-light: var(--mb-base-color-gray-70);
}

[data-metabase-theme="night"][data-metabase-theme="night"] {
  --mb-color-text-selected: var(--mb-base-color-white);
  --mb-color-text-hover: var(--mb-base-color-brand-30);
  --mb-color-border: var(--mb-color-text-medium);
  --mb-color-bg-dashboard: var(--mb-color-bg-black);
  --mb-color-background: var(--mb-color-bg-black);
  --mb-color-background-selected: var(--mb-base-color-brand-60);
  --mb-color-background-hover: var(--mb-base-color-brand-70);
  --mb-color-background-disabled: var(--mb-base-color-gray-70);
  --mb-color-background-brand: var(--mb-base-color-brand-60);

  .EmbedFrame,
    /* this is to make it work when exporting to pdfs,
  where the EmbedFrame is not part of the exported dom */
      .WithThemeBackground {
    background-color: var(--mb-color-bg-dashboard);
    border-color: var(--mb-color-border);
  }

  .EmbedFrameHeader,
  .EmbedFrameFooter {
    background-color: var(--mb-color-bg-dashboard);
    border-color: var(--mb-color-border);
  }

  .EmbedFrame .fullscreenNightText {
    transition: color 1s linear;
  }

  .EmbedFrame svg text {
    stroke: none !important;
  }

  .EmbedFrame .DashCard .Card {
    background-color: var(--mb-color-bg-black);
    border: 1px solid var(--mb-color-border);
  }
}

.NoBackground {
  --mb-color-bg-dashboard: transparent;
}

[data-metabase-theme="transparent"][data-metabase-theme="transparent"] {
  .EmbedFrame {
    background-color: transparent;

    .DashCard .Card {
      background-color: transparent;
    }
  }

  .EmbedFrameHeader,
  .EmbedFrameFooter {
    background-color: transparent;
  }
}
